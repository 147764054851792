import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import SEO from '../../components/seo';
import dateFns from 'date-fns';
import Layout from '../../components/layout';
import { H1, H2, H3, P, Caption } from '../../styles/Typography';

const Confirmation = ({ location }) => (
  <Layout>
    <SEO title="Contact" />
    <Container>
      <Opening>
        <H1>Thank you!</H1>
        <H2 style={{ maxWidth: '17em' }}>
          Your enquiry has been submitted. Lisa will be in touch with you soon.
        </H2>
      </Opening>
      {location.state && (
        <Receipt>
          <Section>
            <H3>Personal details</H3>
            <Caption>
              {location.state.name}
              <br />
              {location.state.email}
              <br />
              {location.state.phone}
            </Caption>
          </Section>

          <Section>
            <H3>Your event</H3>
            <Caption>
              {location.state.occasion}
              <br />
              {location.state.numberOfPeople}{' '}
              {location.state.numberOfPeople === 1 ? 'person' : 'people'}
              <br />
              {dateFns.format(
                new Date(location.state.date),
                'dddd D MMMM YYYY'
              )}
              {location.state.time && <br />}
              {location.state.time && `To be ready by ${location.state.time}`}
            </Caption>
          </Section>

          <Section>
            <H3>Location</H3>
            <Caption>
              {location.state.lisaWillTravel
                ? location.state.address
                : `Lisa's studio`}
            </Caption>
          </Section>

          {location.state.notes && (
            <Section>
              <H3>Additional notes</H3>
              <Caption>{location.state.notes}</Caption>
            </Section>
          )}
        </Receipt>
      )}
      <P>
        <Link to="/">Back to home</Link>
      </P>
    </Container>
  </Layout>
);

export default Confirmation;

const Container = styled.div`
  max-width: 33rem;
  margin: auto;
  padding: 5rem 1.5rem;
  text-align: center;

  h2 {
    margin: 0 auto;
  }
`;

const Opening = styled.div`
  padding: 7.5rem 0 2.5rem;
`;

const Receipt = styled.div`
  padding: 1rem 1.5rem;
  background: #ffffff;
  border-radius: 0.25rem;
  margin-bottom: 2.5rem;
`;

const Section = styled.div`
  padding: 1.5rem;
  /* border-bottom: 1px solid #ebe3e3; */
  border-bottom: 1px solid #f8f3f3;

  &:last-child {
    border: none;
  }
`;
